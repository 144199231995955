import store from "@/state/store";

export default [
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      title: "Home",
      authRequired: false,
    },
    component: () => import("../views/landing/index"),
  },
  {
    path: "/pages/about",
    name: "about",
    meta: { title: "회사소개", authRequired: false },
    component: () => import("../views/pages/about"),
  },
  {
    path: "/pages/erp",
    name: "erp",
    meta: { title: "ERP", authRequired: false },
    component: () => import("../views/pages/daon-erp"),
  },
  {
    path: "/pages/mes",
    name: "mes",
    meta: { title: "MES", authRequired: false },
    component: () => import("../views/pages/daon-mes"),
  },
  {
    path: "/pages/shop",
    name: "printshop",
    meta: { title: "인쇄쇼핑몰", authRequired: false },
    component: () => import("../views/pages/daon-shop"),
  },
  {
    path: "/pages/pricing",
    name: "price",
    meta: { title: "개발가격", authRequired: false },
    component: () => import("../views/pages/pricing"),
  },
  {
    path: "/pages/faqs",
    name: "faq",
    meta: { title: "FAQ", authRequired: false },
    component: () => import("../views/pages/faqs"),
  },
  {
    path: "/pages/contactus",
    name: "contactus",
    meta: { title: "개발문의", authRequired: false },
    component: () => import("../views/pages/contactus"),
  }, 
  {
    path: "/pages/estimate",
    name: "estimate",
    meta: { title: "맞춤견적", authRequired: false },
    component: () => import("../views/pages/daon-estimate"),
  }, 
];